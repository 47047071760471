import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';
import * as CSSVariables from './css-variables.scss';

export const GlobalStyle = createGlobalStyle`
    ${normalize}

    /* ==================================================================== */
    /* Custom Micro Reset | @bcolwell */
    /* ==================================================================== */
    *, *:before, *:after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    /* outline: 1px solid green; */
    }

    a{text-decoration:none; color:inherit; cursor:pointer;}
    button{background-color:transparent; color:inherit; border-width:0; padding:0; cursor:pointer;}
    button:focus {outline: none;}
    figure{margin:0;}
    input {border: 0; }
    input::-moz-focus-inner {border:0; padding:0; margin:0;}
    ul, ol, dd{margin:0; padding:0; list-style:none;}
    h1, h2, h3, h4, h5, h6{margin:0; font-size:inherit; font-weight:inherit;}
    p{margin:0;}
    cite {font-style:normal;}
    fieldset{border-width:0; padding:0; margin:0;}
    textArea{border: 0;}

    /* ==================================================================== */
    /* Global Styles */
    /* ==================================================================== */

    /* Theme */
    ${CSSVariables} //! This is split into a separate CSS file to accomodate intellisense

    body {
        font-family: Proxima Nova, sans-serif;
        font-size: 16px; //For browsers that don't support calc()
        // font-size: calc(
        //   $min_font + ($max_font - $min_font) *
        //     ((100vw - $min_width) / ($max_width - $min_width))
        // );
        font-size: calc(
            14px + (16 - 14) * ((100vw - 375px) / (1400 - 375))
        );
        font-weight: var(--font-weight-regular);
      }

      input, textarea {
        font-family: Proxima Nova;
        font-weight: var(--font-weight-light);
        font-size: var(--font-size-P1);
        line-height: 24px;
        letter-spacing: 0em;
      }
`;

/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

import { GlobalStyle } from '../theme/global-style';
import 'react-modal-video/scss/modal-video.scss'; // to style external modal library

const fullScreenPaths = ['/director-setup/', '/director-setup'];
const simpleFooterPaths = [];

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  if (fullScreenPaths.includes(children.props.location.pathname)) {
    return <main>{children}</main>;
  }

  return (
    <>
      {/* <Header siteTitle={data.site.siteMetadata.title} /> */}
      <GlobalStyle />
      <main>{children}</main>
      {/* <Footer
        simple={simpleFooterPaths.includes(children.props.location.pathname)}
      /> */}
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
